img.logo-one {
    width: 190px;
}
img.logo-one.rg-tlc {
    width: 130px;
}
.log-start {
    padding-top: 30px;
}
.reg-header{
    padding-top: 20px;
    text-align: center;
}
.link-log-text {
    color:#f60;
    text-decoration: none;
    cursor: pointer;
}
.link-log-text {
    color: rgb(222, 89, 0);
}
.space-flex {
    display: flex;
    justify-content: space-between;
}
.text-form {
    font-size: 8px;
}
.smal-g {
    color:gray;
    font-size: 10px;
}