body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.form-card {
  padding: 20px;
}
.matetrial-input {
  border-bottom: 2px solid #f60;
  border-top: none;
  border-left: none;
  border-right: none;
}
.form-control {
  border-radius: 0px;
}
.form-group {
  margin-top: 20px;
}
.btn-full{
  width: 100%;
}
.btn.btn-mevent{
   background-color: #f60;
   padding: 10px 10px;
   color:#fff;
  border-radius: 30px;
}
.btn.btn-mevent:hover{
  background-color: rgb(210, 86, 3);
}
.btn.btn-mevent-off {
  background-color: #4f4f4f;
  padding: 10px 10px;
  color: #fff;
  border-radius: 30px;
}
.btn.btn-mevent-off {
  background-color: #3f3f3f;
}
.supvoter {
  width: 80%;
}
.dot1 {
  height: 95px;
  width: 95px;
  position: fixed;
  top: 10px;
  right: -2%;
}
.dot2 {
  height: 205px;
  width: 205px;
  position: fixed;
  top: 8%;
  left: 4%;
}
.dot3 {
  height: 110px;
  width: 110px;
  position: fixed;
  bottom: 30%;
  left: 45%;
}
/* SPINNER START */
.loadingSpinnerContainer {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 5000;
  display: flex;
  justify-content: center;
  align-items: center;
}

.loadingSpinner {
  width: 64px;
  height: 64px;
  border: 8px solid;
  border-color: #f60 transparent rgb(250, 117, 29) transparent;
  border-radius: 50%;
  animation: spin 1.2s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

/* SPINNER END */
img.imge-profile {
  width: 50px;
  border-radius: 50%;
}
.main-standard-version {
  background: #fffefc;
  display: block;
  height: 100%;
  width: 100%;
  position: relative;
}
.main-in-vers {
  background: #fffefc;
  display: block;
  height: 100%;
  width: 100%;
  position: relative;
}
.shade-pad {
  background: #f2f2f2;
  padding: 10px;
  border-radius: 12px;
}
.gray-text {
  color: gray;
}
.nav-block {
  background-color: #f60;
  padding: 10px;
  color: #fff;
  font-weight: 600;
}
a.backbutton {
  color: #fff;
  padding: 10px 20px;
  display: block;
}
.title-screen {
  padding-top: 11px;
  padding-left: 11px;
}
.orange-caps{
  color: #f60;
  text-transform: uppercase;
  font-weight: 900;
  font-size: 13px;
}
.card.item-card.image-card {
  width: 300px;
  height: 300px;
  padding: 0px;
}
.blog-imagery {
  width: 100%;
  height: 300px;
}
.date-content {
  color: #939090;
  padding: 10px 0px;
}
.txtSize2 {
  font-size: 18px;
}
.thin {
  font-weight: 100;
}
.bold {
  font-weight: bold;
}
.flexme{
  display: flex;
}
.flex_10 {
  width: 10%;
}
.flex_30 {
  width: 30%;
}
.flex_40 {
  width: 40%;
}
.flex_50 {
  width: 50%;
}
.flex_60 {
  width: 60%;
}
.flex_70 {
  width: 70%;
}


.sm-text {
  font-size: 10px;
}
h4.smTitle {
  font-weight: 100;
  margin-top: 15px;
}
.processing-figure {
  width: 60px;
}
.side-horizontal-scroller {
  display: flex;
  flex-wrap: nowrap;
  overflow-x: auto;
  padding: 7px;
}
.card{
  padding: 20px;
  border-radius: 20px;
}
.side-horizontal-scroller .card{
  flex: 0 0 auto;
}
.full-image {
  width: 100%;
  height: 100px;
}
.full-image2{
  width: 100%;
  height: 200px;
}
.card.item-card {
  width: 200px;
  margin-right: 15px;
  height: 210px;
}
.bl-title {
  white-space: wrap;
  font-weight: 800;

  height: 50px;
  display: block;
  width: 100%;
}
.pdtop10 {
  padding-top: 10px;
}
.pd10 {
  padding:10px;
}
.pd20 {
  padding:20px;
}

.mgtop10 {
  margin-top: 10px;
}
.mgtop20 {
  margin-top: 20px;
}
.mgtop30 {
  margin-top: 30px;
}
.mgtop50 {
  margin-top: 50px;
}
.mgbot10 {
  margin-bottom: 10px;
}
.mgbot20 {
  margin-bottom: 20px;
}
.link-dark {
  text-decoration: none;
  color:#181717;
}
.article-content {
  font-weight: 200;
}
.content-screen {
  position: relative;
}
iframe.full-page {
  width: 100%;
  height: 100vh;
}
.image-over-filled {
  background: #37373769;
  height: 300px;
  border-radius: 18px;
  position: relative;
}
.sect-evebot {
  position: absolute;
  bottom: 15px;
  padding-left: 10px;
  color: #fff;
}
h1.special-gray {
  color: #9d9a9a;
  font-weight: 900;
}
.text-right {
  text-align: right;
}
.slider-block{
  padding: 10px 15px;
}
/******/
/**** Event Banner*/
.banner-slider {
  width: 100%;
  height: 300px; /* Adjust the height as needed */
  overflow: hidden;
}

.banner-slider img {
  width: 100%;
  height: 100%;
  object-fit: cover; /* Ensures the image covers the entire container */
}
.img-banner {
  height: 270px !important;
  border-radius: 10px;
  margin-right: 10px;
}
/*******/

.ev-title{
  font-weight: 800;
}

.mevent-item-card {
  margin-right: 15px;
  position: relative;
  width: 230px;
}
.side-horizontal-scroller .mevent-item-card {
  flex: 0 0 auto;
}
.back-white{
  background-color: #fff;
}
.setting-heard {
  padding: 11px 0px 4px;
  font-weight: 900;
  font-size: 12px;
}
.gray-back {
  background: #f2f2f2;
}
.card-gray {
  background: rgb(162, 162, 162);
  width: 100%;
  height: 165px;
  border-radius: 25px;
  margin-top: 70px;
}
.ct-bottom {
  position: absolute;
  bottom: 7px;
  left: 15px;
}
.logo-two{
  width: 150px;
}
.card-white {
  background: #fff;
  text-align: center;
  width: 180px;
  border: 1px solid #fefefe;
  position: absolute;
  border-radius: 10px;
  left: 20px;
}
.card.card-list-item {
  background: #d9d9d9;
  cursor: pointer;
}

.icon-box {
  color: #f60;
  width: 20%;
  font-size: 25px;
  text-align: center;
}
.icon-box-black {
  color: #5a5a5a;
  width: 10%;
  font-size: 20px;
  text-align: center;
}
.content-box-time {
  width: 60%;
}
.flexme.fljust {
  justify-content: space-between;
}
.section-item {
  border-bottom: 1px solid #e7e7e7;
}
.section-item.speakers-sect .card.item-card {
  padding: 10px;
  height: auto !important;
  background: #e7e7e7;
  width:260px;
}
.txt-short {
  font-size: 12px;
}
.section-item.speakers-sect .full-image {
  height: 200px;
  border-radius: 13px;
  margin-bottom: 10px;
}
/** MODAL Start **/
.modal-shade {

  position: fixed;
  z-index: 1;
  padding-top: 100px;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: rgb(0, 0, 0);
  background-color: rgba(0, 0, 0, 0.4);
}
.modal-footer {
  background: #e7e7e7;
}
/*** MODAL END **/
.img-front {
  width: 50px;
  height: 50px;
  margin-right: 20px;
  border-radius: 50%;
}
img.large-logo {
  width: 70%;
}
input.ophide {
  opacity: 0;
}
.rating-stream {
  padding-bottom: 10px;
}
.rating-stream label {
  padding: 10px !important;
}
.line {
  border-bottom: 1px solid #f2f2f2;;
}
.back-white .line{
  padding-bottom: 10px;
  font-size: 12px;
}
.proccesing-image{
  width: 100px;
}
.gif-div {
  position: fixed;
  top: 35%;
  left: 40%;
}
.card.card-list-item.found-true {
  background: #fff1e6;
}
.card.card-list-item.found-true .icon-box-black{
  color:#f60;
}
img.celebrate-full {
  width: 100%;
  border-radius: 15px;
}
img.success-image {
  width: 300px;
}
.full-container {
  padding: 10px 100px;
}
/* CSS for the accordion */
.accordion-mev {
  margin-top: 10px;
}
.accordion-input {
  display: none;
}
.accordion-label {
  display: block;
  cursor: pointer;
  font-weight: bold;
  background: linear-gradient(135deg, #e4992f 0, #f27d2a 60%);
  color: #e9e9e9;
  padding: 10px;
  border-top-right-radius: 15px;
  border-top-left-radius: 15px;
  font-size: 14px;
}
.accordion-content {
  display: none;
}
.accordion-input:checked + .accordion-label + .accordion-content {
  display: block;
}
/* CSS for the accordion END */
@media only screen and (min-width: 700px) {
  .main-login-data {
      width: 50%;
      margin: auto;
  }
}